
import {debounceTime} from 'rxjs/operators';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {DeliveryMethodGridComponent} from '../../components/delivery-method-grid/delivery-method-grid.component';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoggingService, PagedResult} from '@tymes4-shared';
import { HttpLoaderService } from '@tymes4-shared';
import {ConfirmService} from '@tymes4-shared';
import { PassePartoutValidator } from './passe-partout.validator';
import {LinkPricelistComponent} from 'app/shared/components/link-pricelist/link-pricelist.component';
import {ListViewSelectionMode} from '@tymes4-shared';
import {TranslateService} from "@ngx-translate/core";
import { AllEventsForPassePartoutView, AllEventsForPassePartoutViewPagedResult, ETicketTemplateService, HardcardTemplate, HardcardTemplateService, PassePartout, PassePartoutDeliveryMethodView, PassePartoutDetail, PassePartoutService, PassePartoutStoreArgs, PassePartoutType, PassePartoutTypeService, PaymentMethodModel, PurchaseRightService, Season, SeasonService } from '../../api';
import { ApplicationModuleHelperService, ApplicationModules } from '../../services/application-module.service';
import { AccountingDimensionHelperService } from '../../services/accounting-dimension.service';

@Component({
  selector: 'app-edit-passe-partout-form',
  templateUrl: './edit-passe-partout-form.component.html',
  styleUrls: ['./edit-passe-partout-form.component.scss']
})
export class EditPassePartoutFormComponent implements OnInit {
  @ViewChild('deliveryMethodComponent') deliveryMethodComponent: DeliveryMethodGridComponent;
  @ViewChild('priceListComponent') linkPricelistComponent: LinkPricelistComponent;
  @ViewChild('pagerComponent') pagerComponent: any;


  currentTabId = 'details';

  public selectableHardcardTemplates: Array<HardcardTemplate> = [];
  public eTicketTemplates = [];
  private isNew: boolean = false;
  private passePartout: any = {};
  public form: FormGroup;
  private eventSearchControl: FormControl;

  public pagedPptEvents: any = [];
  public passePartoutEvents: Array<AllEventsForPassePartoutView> = [];

  public saving: boolean = false;
  public passePartoutId: number;
  public paymentmethods: Array<PaymentMethodModel> = null;

  public passePartoutTypes: Array<PassePartoutType> = [];
  public precedingPassePartouts: Array<PassePartout> = [];
  public seasons: Array<Season> = [];
  public deliveryMethodData: Array<PassePartoutDeliveryMethodView> = [];
  selectablePrecedingPassePartouts: Array<PassePartout> = [];
  public showMobileTicketSection = false;
  public showMarketplaceSection = false;

  public showDim1: boolean = false;
  public showDim2: boolean = false;
  public showDim3: boolean = false;
  public showDim4: boolean = false;
  public showDim5: boolean = false;

  validationMessages = {
    'Named': [
      {type: 'namedNotCorrect', message: 'FORM.VALIDATION.NAMEDNOTCORRECT'}
    ]
  }

  @ViewChild('paymentListVw') channelListVw;


  public paymentMethodsListOptions = {
    search: false,
    pageSize: 12,
    disableSort: true,
    renderInCard: false,
    noItemsText: this.translate.instant('GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND'),
    searchTitle:  this.translate.instant('MANAGEMENT.MULTI-EVENT.EDITDIALOG.SEARCH.TITLE'),
    selectionMode: ListViewSelectionMode.None,
    headers: [
      {sortField: null, label: '', width: 50, hideOn: null},
      {sortField: null, label: this.translate.instant('MANAGEMENT.MULTI-EVENT.EDITDIALOG.HEADER.LISTVW.NAME'), width: null, hideOn: null},
    ]
  };


  // selectableSeasons
  // selectablePassePartoutTypes
  selectablePassePartoutTypes: Array<PassePartoutType> = [];

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<EditPassePartoutFormComponent>,
              private passePartoutService: PassePartoutService,
              private passePartoutValidator: PassePartoutValidator,
              private confirmService: ConfirmService,
              private loader: HttpLoaderService,
              private purchaseRightService: PurchaseRightService,
              private passePartoutTypeService: PassePartoutTypeService,
              private hardcardTemplateService: HardcardTemplateService,
              private translate: TranslateService,
              private seasonService: SeasonService,
              private eTicketTemplateService: ETicketTemplateService,
              private applicationModuleHelperService: ApplicationModuleHelperService,
              private accountingDimensionHelperService: AccountingDimensionHelperService) { }
              //private eTicketTemplateService: ETicketTemplateService) { }


  ngOnInit() {
    this.applicationModuleHelperService.getActiveModules().subscribe((module) => {
      this.showMarketplaceSection = (module && module.includes(ApplicationModules.SecondaryTicketing));
      this.showMobileTicketSection = (module && module.includes(ApplicationModules.MobileTicketing));
    })

    this.accountingDimensionHelperService.getActiveAccountingDimensions().subscribe((activeDimensions) => {
      this.showDim1 = (activeDimensions && activeDimensions.includes('accountingdimension1'));
      this.showDim2 = (activeDimensions && activeDimensions.includes('accountingdimension2'));
      this.showDim3 = (activeDimensions && activeDimensions.includes('accountingdimension3'));
      this.showDim4 = (activeDimensions && activeDimensions.includes('accountingdimension4'));
      this.showDim5 = (activeDimensions && activeDimensions.includes('accountingdimension5'));
    })

    this.isNew = this.passedData.isNew;
    this.passePartout = this.passedData.passepartout;


      this.form = new FormGroup({
        Id: new FormControl(0),
        Name: new FormControl('', [Validators.required]),
        Code: new FormControl('', { validators: Validators.required, asyncValidators: [this.passePartoutValidator.passePartoutCodeTaken.bind(this)], updateOn: "blur" }),
        IdentificationRequired: new FormControl(false),
        PassePartoutTypeId: new FormControl(null, [Validators.required]),
        Active: new FormControl(true),
        ExtraInfo: new FormControl(''),
        Named: new FormControl(false),
        IsUniquelyAssigned: new FormControl(false),
        PhotoRequired: new FormControl(false),
        HasSoldOut: new FormControl(false),
        ApplyIndexing: new FormControl(true),
        PrecedingPassePartoutId: new FormControl(null),
        KeepPrecedingBarcode: new FormControl(false),
        SeasonId: new FormControl('', Validators.required),
        AccountingExternalCode: new FormControl(''),
        HardcardTemplateId: new FormControl(-1),
        MobileTicketFrontTemplateId: new FormControl(-1),
        MobileTicketBackTemplateId: new FormControl(-1),
        MaxResell: new FormControl(0),
        MaxSeasonCardAmount: new FormControl(0),
        MaxSwap: new FormControl(0),
        LimitResell: new FormControl(false),
        AdditionalAttribute: new FormControl(''),
        AccountingDimensionCode1: new FormControl(''),
        AccountingDimensionCode2: new FormControl(''),
        AccountingDimensionCode3: new FormControl(''),
        AccountingDimensionCode4: new FormControl(''),
        AccountingDimensionCode5: new FormControl(''),
        ShowMobileTicketInTicketshop: new FormControl(false),                                     
      });

      if (this.passePartout === void 0) this.passePartout = {};
      this.passePartoutId = this.passePartout.Id;

      this.passePartoutValidator.editingObject = this.passePartout;

    if (!this.isNew) {

      this.passePartoutService.getPassePartoutDetail(this.passePartout.Id).subscribe((pptDetails: PassePartoutDetail) => {
        let additionalFilters : { [key: string]: string; } = {};
        additionalFilters['passePartoutId'] = this.passePartout.Id.toString();
        
        this.passePartoutService.searchForPassePartoutEvents('', 1, 99999999, 'Name', true, additionalFilters).subscribe((data: AllEventsForPassePartoutViewPagedResult) => {
          this.passePartoutEvents = data.Records;
          this.setPage(1);
        });

        //close this dialog and pass the newly added id to the called
        if (pptDetails.HardcardTemplateId === null) pptDetails.HardcardTemplateId = -1;
        if (pptDetails.MobileTicketFrontTemplateId === null) pptDetails.MobileTicketFrontTemplateId = -1;
        if (pptDetails.MobileTicketBackTemplateId === null) pptDetails.MobileTicketBackTemplateId = -1;
        this.passePartout = pptDetails;
        this.form.patchValue(this.passePartout);

        this.onPrecedingPassePartoutChange(null);
      });

      this.passePartoutService.getPassePartoutDeliveryMethods(this.passePartoutId).subscribe((deliveryMethods: Array<PassePartoutDeliveryMethodView>) => {
        this.deliveryMethodData = deliveryMethods;
        this.deliveryMethodComponent.data = deliveryMethods;
        this.deliveryMethodComponent.initialize();
      });
    }


    this.passePartoutTypeService.getAllPassePartoutTypes().subscribe((data: Array<PassePartoutType>) => {
      this.passePartoutTypes = data;
      this.selectablePassePartoutTypes = this.passePartoutTypes;
    });

    this.loadHardcardTemplates();
    this.loadSeasons();

    this.passePartoutService.getPastPassePartouts().subscribe((data: Array<PassePartout>) => {
      this.precedingPassePartouts = data;
      this.selectablePrecedingPassePartouts = this.precedingPassePartouts;

    });
  }

  loadHardcardTemplates() {

    this.hardcardTemplateService.listSelectableHardcardTemplates().subscribe((data: Array<HardcardTemplate>) => {
      this.selectableHardcardTemplates = data;
      //add the empty option
      this.selectableHardcardTemplates.splice(0, 0, { Name: this.translate.instant('GENERIC.DROPDOWN.NONE'), Id: -1});

    });

    this.eTicketTemplateService.listETicketTemplatesByType(2).subscribe((data: any) => {
      this.eTicketTemplates = data;
      this.eTicketTemplates.unshift({ Name: '[Geen]', Id: -1 });
    });

  }

  updatePaymentmethod(paymentMethod, isChecked) {
    paymentMethod.HasPaymentMethod = isChecked;
  }

  onTabChange(e) {
    this.currentTabId = e.selectedTab.id;
  }


  doPageEvents(pageArgs) {
    this.setPage(pageArgs.pageNr);
  }

  setPage(pageNr: number) {

      this.pagedPptEvents = this.passePartoutEvents;
      let length = this.passePartoutEvents.length; //we need a copy
      this.pagerComponent.setObjectCount(length);

      this.pagedPptEvents = this.pagedPptEvents.slice((pageNr - 1) * this.pagerComponent.pageSize, (pageNr) * this.pagerComponent.pageSize);
      this.pagerComponent.setPage (pageNr);

  }


  onDataRequest(event) {
      if (this.paymentmethods === null) {
        var method = this.isNew ? this.passePartoutService.getNewPassePartoutPaymentMethods() : this.passePartoutService.getPassePartoutPaymentMethods(this.passePartout.Id);

        method.subscribe((data: Array<PaymentMethodModel>) => {
          this.paymentmethods = data;
  
          const d : PagedResult = {
            TotalRecords: this.paymentmethods.length,
            Records: this.paymentmethods
          };
           
          this.channelListVw.Data = d;
        });
  
      }
      else {
        const d : PagedResult = {
          TotalRecords: this.paymentmethods.length,
          Records: this.paymentmethods
        };
         
        this.channelListVw.Data = d;
      }
  }


  selectableSeasons: Array<Season> = null;

  loadSeasons() {

    if (this.isNew) {
      this.seasonService.getCurrentAndFutureSeasons().subscribe((data: Array<Season>) => {

        this.seasons = data;
        this.selectableSeasons = this.seasons;

        if (this.seasons.length > 0) {
          // default is current season (first from the list)
          this.form.controls.SeasonId.setValue(this.seasons[0].Id);
        }
      });
    } else {
      this.seasonService.getAllSeasons().subscribe((data: Array<Season>) => {
        this.seasons = data;
        this.selectableSeasons = this.seasons;
      });
    }
  }

  loadTabDetails(event) {
    const idx = event.index;

    if (idx == 3) {
      //prijslijsten, lazy load.
      this.linkPricelistComponent.ensureInitialized();
    } else if(idx === 4){
      if(this.deliveryMethodData !== null)
        this.deliveryMethodComponent.initialize();
    }
  }


  onNamedChange(e) {

    if (e.checked === false) {
      if (this.passePartoutId) {
        this.purchaseRightService.checkNonTransferablePassePartout(this.passePartoutId).subscribe((data: boolean) => {
          // check if all are transferable
          if (data !== null && data !== void 0 && data) {
            this.form.controls.Named.setErrors({namedNotCorrect: true});
            this.form.controls.Named.markAsTouched();
          } else {
            if (this.form.controls.Named.hasError('namedNotCorrect')) {
              this.form.controls.Named.setErrors(null);
              this.form.controls.Named.markAsTouched();
            }
          }
        });
      }
    } else {
      if (this.form.controls.Named.hasError('namedNotCorrect')) {
        this.form.controls.Named.setErrors(null);
        this.form.controls.Named.markAsTouched();
      }
    }
  }

  onLimitResellChange(e) {
    if(e.target.checked == true) {
      this.form.controls.MaxResell.enable();
      this.form.controls.MaxResell.setValidators([Validators.required, Validators.min(1)]);
      this.form.controls.MaxSeasonCardAmount.enable();
    } else {
      this.form.controls.MaxResell.disable();
      this.form.controls.MaxSeasonCardAmount.disable();
      this.form.controls.MaxResell.clearValidators();
    }

    this.form.controls.MaxResell.updateValueAndValidity();
    this.form.controls.MaxSeasonCardAmount.updateValueAndValidity();
  }

  onPrecedingPassePartoutChange(e) {
    let precedingPpId = this.form.controls.PrecedingPassePartoutId.value;

    if (precedingPpId != null && precedingPpId != '')
    {
      this.form.controls.KeepPrecedingBarcode.enable();
    }
    else
    {
      this.form.controls.KeepPrecedingBarcode.setValue(false);
      this.form.controls.KeepPrecedingBarcode.disable();
    }
  }

  submit(){

    var ppt = this.form.value;
    this.storePassePartout(ppt);
  }

  cleanDeliveryMethodDataForRequest(data) {
    const cleaned = [];
    for (const d of data) {
      cleaned.push({
        Id: d.PassePartoutDeliveryMethodId,
        DeliveryMethodId: d.DeliveryMethodId,
        PassePartoutId: d.PassePartoutId,
        VenueBuildingBlockId: d.VenueBuildingBlockId,
        Enabled: d.Enabled
      });
    }
    return cleaned;
  }

  storePassePartout (ppt) {

    this.loader.open();

    var hasPaymentMethodsSelected = (this.paymentmethods.filter(function(p) {return p.HasPaymentMethod}).length > 0)

    if (!hasPaymentMethodsSelected){
      this.loader.close();
      this.confirmService.confirm({ title: this.translate.instant('MANAGEMENT.MULTI-EVENT.EDITDIALOG.SAVE.ERROR-NOT-POSSIBLE'),
        message: this.translate.instant('MANAGEMENT.MULTI-EVENT.EDITDIALOG.SAVE.MESSAGE.ERROR-NOT-POSSIBLE'),
        okonly: true}).subscribe(() => {
      });
    }
    else{

      if (+ppt.HardcardTemplateId == -1) ppt.HardcardTemplateId = null;

      const accountingDimension = {
        Code1: ppt.AccountingDimensionCode1,
        Code2: ppt.AccountingDimensionCode2,
        Code3: ppt.AccountingDimensionCode3,
        Code4: ppt.AccountingDimensionCode4,
        Code5: ppt.AccountingDimensionCode5
      }

      const args : PassePartoutStoreArgs = {
        LinkedPriceLists: this.linkPricelistComponent.linkedLists,
        PassePartout: ppt,
        PaymentMethods: this.paymentmethods,
        DeliveryMethods: this.cleanDeliveryMethodDataForRequest(this.deliveryMethodData),
        AccountingDimension: accountingDimension
      };

      if (this.isNew) {
        this.passePartoutService.createPassepartout(args).subscribe((data: number) => {
          this.loader.close();
          this.dialogRef.close(true);
        });
      }
      
      else {
        args.DeliveryMethods = this.cleanDeliveryMethodDataForRequest(this.deliveryMethodData);

        this.passePartoutService.updatePassepartout(args).subscribe(data => {
          this.loader.close();
          this.dialogRef.close(true);
        });
      }
    }
  }


}
